import React from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { mobile, tabletPortrait } from '../responsive';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem } from '../redux/APIs/CartAPIs';

const Card = styled.div`
  width: 18rem;
  margin: 8px;
  &:hover {
    box-shadow: 3px 3px 5px lightgray;
  }
  ${mobile({ width: '14rem' })}
  ${tabletPortrait({ width: '14rem' })}
`;

const Button = styled.button`
  padding: 6px 20px;
  margin-right: 5px;
  background-color: #05648e;
  color: white;
  border: none;
  border-radius: 2px;
`;

const BuyButton = styled.button`
  padding: 6px 20px;
  margin-right: 5px;
  background-color: #02af4a;
  color: white;
  border: none;
  border-radius: 2px;
`;
const Product = ({ product, index }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  let imgUrl = `${BASE_URL}/${product.img_path}`.replace('\\', '/');

  const handleSubmit = () => {
    if(!user.isLoggedIn) {
      Swal.fire({
        titleText: 'Please login to Add to Cart.',
        showCancelButton: true,
        showConfirmButton: true,
        icon: 'question',
        confirmButtonText: 'Proceed to Login',
        cancelButtonText: 'Continue Exploring'
      }).then((result) => {
        if(result.isConfirmed) {
          navigate('/login', { state: { url: location.pathname } });
        }
      })
    }
    let payload = {
      username: user.currentUser.user.username,
      productId: product.id,
      quantity: 1,
      updatedAt: moment().utc().format()
    }
    addCartItem(dispatch, payload);
    navigate('/cart');
  };


  return (
    <>
      <Card className='card'>
        <img src={imgUrl} className='card-img-top' alt='...' />
        <div className='card-body'>
          <h5 className='card-title'>
            {product.title && product.title.length > 40
              ? product.title.slice(0, 40) + '...'
              : product.title}
          </h5>
          <p className='card-text'>
            {product.description && product.description.length > 80
              ? product.description.slice(0, 70) + '...'
              : product.description}
          </p>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Rs.{product.price}
          </p>
          <Link to={`/product/${product.id}`}>
            <Button>Details</Button>
          </Link>
          <BuyButton onClick={handleSubmit}>Buy Now</BuyButton>
        </div>
      </Card>
    </>
  );
};

export default Product;
