import { createSlice } from "@reduxjs/toolkit";

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    isLoading: false,
    message: "",
    data: [],
  },
  reducers: {
    startSaveDelivery: (state, action) => {
      state.isLoading = true;
    },
    saveDeliverySuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "Address Saved.";
    },
    saveDeliveryFailed: (state, action) => {
      state.isLoading = false;
      state.message = "Address not saved.";
    },
    startGetDeliveryData: (state, action) => {
      state.isLoading = true;
    },
    getuserDelLocSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "Details fetched Successfully.";
    },
    getuserDelLocFailed: (state, action) => {
      state.isLoading = false;
      state.message = "Details not fetched.";
    },
    deleteUserDelLocSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "Details deleted Successfully.";
    },
    deleteUserDelLocFailed: (state, action) => {
      state.isLoading = false;
      state.message = "Details not deleted.";
    },
  },
});

export const {
  startSaveDelivery,
  saveDeliveryFailed,
  saveDeliverySuccess,
  getuserDelLocFailed,
  getuserDelLocSuccess,
  startGetDeliveryData,
  deleteUserDelLocFailed,
  deleteUserDelLocSuccess,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
