import React, { useEffect } from "react";
import styled from "styled-components";
import Announcements from "../components/Announcements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const Wrapper = styled.div`
  margin: 30px;
`;

const TopContent = styled.div``;

const TopContentTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  text-decoration: underline;
  color: orange;
  text-align: center;
`;

const TopTitle = styled.p`
  text-decoration: underline;
  font-weight: bold;
  font-size: 18px;
  color: #4d4dea;
`;

const TopDesc = styled.span`
  font-size: 16px;
`;

const MiddleContent = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
`;

const MiddleContentContainer = styled.div`
  flex: 1.5;
`;

const MiddleImageContainerOut = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  ${mobile({ flexFlow: 'row wrap', justifyContent: 'center' })}

`;
const MiddleImageContainer = styled.div`
${mobile({ marginBottom: '10px' })}
`;

const BottomImageContainer = styled.div`
  flex-direction: row;
  ${mobile({ marginBottom: '10px' })}
  ${tabletPortrait({ marginBottom: '10px', marginRight: '10px' })}
  ${tabletLandscape({ marginBottom: '10px', marginRight: '10px' })}
`;


const MiddleContentContainerOut = styled.div`
  display: flex;
  flex-direction: row;
  ${mobile({ flexFlow: 'row wrap' })}
`;

const MiddleContentTitleContainer = styled.div`
  font-weight: 550;
`;

const MiddleContentTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-decoration: underline;
  color: #2cc168;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;

const MiddleContentBox = styled.div`
  flex: 1;
  margin: 30px;
`;

const MiddleContentBoxTitle = styled.div`
  color: #073763;
  text-decoration: underline;
  font-weight: 700;
`;

const MiddleContentBoxDesc = styled.div`
  font-size: 15px;
`;

const BottomContainerBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomContainerTitle = styled.div`
  font-weight: 800;
  color: #548dd4;
  margin-bottom: 12px;
`;

const BottomContainerDesc = styled.div``;

const YoutubeFrameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  margin: 20px;
  ${mobile({ flexDirection: 'column' })}
`;

const YoutubeFrame = styled.iframe`
height: 379px;
width: 558px;
${mobile({ height: '300px', width: '80vw', marginBottom: '15px' })}
${tabletPortrait({ height: '300px', width: '80vw', marginBottom: '15px', marginRight: '10px' })}
`;


const Training = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>Training & Projects</Title>
      <Wrapper>
        <h3>Beginners Course Overview</h3>
        <p>
          Impact Technologies offers step by step foundation courses for
          robotics where you can learn the fundamentals. We offer Robotics where
          you can learn core techniques for representing robots that perform
          physical tasks in the real world. It will cover the fundamentals of
          robotics, focusing on both the mind and the body.
        </p>
        <div
          style={{
            display: "flex",
            margin: "50px 10px",
            justifyContent: "space-around",
            flexFlow: 'row wrap'
          }}
        >
          <BottomImageContainer>
            <img
              src={require("../Assets/Training/7.png")}
              height={200}
              width={250} alt=""
            />
          </BottomImageContainer>
          <BottomImageContainer>
            <img
              src={require("../Assets/Training/2.png")}
              height={200}
              width={250} alt=""
            />
          </BottomImageContainer>
          <BottomImageContainer>
            <img
              src={require("../Assets/Training/6.jpg")}
              height={200}
              width={250} alt=""
            />
          </BottomImageContainer>
          <BottomImageContainer>
            <img
              src={require("../Assets/Training/1.jpg")}
              height={200}
              width={250} alt=""
            />
          </BottomImageContainer>
        </div>
        <YoutubeFrameContainer>
          <YoutubeFrame
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            src={`https://www.youtube.com/embed/${"QZPnZurqCLU"}`}
            frameBorder="0"
          />
          <YoutubeFrame
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            src={`https://www.youtube.com/embed/${"gR4q2GQzoFA"}`}
            frameBorder="0"
          />
        </YoutubeFrameContainer>
        <TopContent>
          <TopContentTitle>Why Coding for Kids?</TopContentTitle>
          <TopTitle>Are coding classes for kids suitable?</TopTitle>
          <TopDesc>
            When you're a parent exploring the possibilities that coding classes
            for kids can bring, you're probably wondering whether coding courses
            will suit your child. The benefits of learning to code courses
            extend far beyond future job opportunities. Impact Technologies
            teaches coding for kids in programs designed to stimulate
            creativity, problem-solving skills, and academic performance
            overall. Over the longer term, online coding courses are a great way
            to train learners for an increasingly tech-oriented world.
            <br />
            Your kids can learn logic, structure, sequence and creative
            expression George Land & Beth Jar man's landmark NASA study
            identified that kids peak in creativity at age 6, followed by a
            gradual decline as they grow up.
            <br />
            The real promise of coding is not that kids will become computer
            engineers. Coding is a fun tool for kids to exercise their desire to
            build, curiosity to question, imagination to explore - the traits
            that will serve any child well in nearly any career, in every hobby,
            and in life.
          </TopDesc>
          <TopTitle>Explore a Career in Robotics</TopTitle>
          <TopDesc>
            Get an introduction to robotics with one of many courses on Impact
            Technologies and see if a job in the next generation robotics
            industry is right for you. Take a course in robotics fundamentals,
            machine learning or artificial intelligence and gain a clear
            understanding of the field and what you need to excel. Many courses
            are self-paced so you can enroll now and start today.
          </TopDesc>
        </TopContent>
        <MiddleContent>
          <MiddleContentTitle>
            WHAT WILL YOU LEARN IN CODING COURSE FOR KIDS?
          </MiddleContentTitle>
          <MiddleContentContainerOut>
            <MiddleContentContainer>
              <MiddleContentTitleContainer>
                Impact Technologies coding courses are oriented towards coding
                and programming. You will learn:
                <ul
                  style={{
                    listStyleType: "square",
                    fontWeight: "normal",
                    lineHeight: 1.8,
                  }}
                >
                  <li>The essentials of coding: Logic, Structure, Sequence</li>
                  <li>To create Websites, Animations & Applications.</li>
                  <li>To manipulate data using programming languages.</li>
                  <li>How to be a problem solver</li>
                  <li>How technologies converse with each other</li>
                </ul>
              </MiddleContentTitleContainer>
            </MiddleContentContainer>
            <MiddleImageContainerOut>
              <MiddleImageContainer>
                <img
                  src={require("../Assets/Training/3.png")}
                  height={250}
                  width={240}
                  style={{ marginRight: "20px" }}
                  alt=""
                />
              </MiddleImageContainer>
              <MiddleImageContainer>
                <img
                  src={require("../Assets/Training/4.png")}
                  height={250}
                  width={240}
                  alt=""
                />
              </MiddleImageContainer>
            </MiddleImageContainerOut>
          </MiddleContentContainerOut>
          <MiddleContentContainerOut>
            <MiddleContentBox>
              <MiddleContentBoxTitle>
                Digital Electronic & Communication
              </MiddleContentBoxTitle>
              <MiddleContentBoxDesc>
                <ul style={{ padding: "10px" }}>
                  <li>Robotic Project by Arduino & Microcontroller</li>
                  <li>Learn Microcontrollers@ Chip level programming</li>
                  <li>Interfacing Bluetooth & Wi-Fi Module to other devices</li>
                  <li>Sensors Application & how to use it as a input</li>
                  <li>Invent Application for Computer & Mobile</li>
                </ul>
              </MiddleContentBoxDesc>
            </MiddleContentBox>
            <MiddleContentBox>
              <MiddleContentBoxTitle>
                Computer Science & Networking
              </MiddleContentBoxTitle>
              <MiddleContentBoxDesc>
                <ul style={{ padding: "10px" }}>
                  <li>C & C++ Programming logic & Techniques</li>
                  <li>Oracle, Core Java, Advance Java</li>
                  <li>HTML/DHTML, XML, FTP</li>
                  <li>VB.NET, C#.NET lls, SQL Server</li>
                  <li>3D Animation, Visualization & Sp. Effect</li>
                </ul>
              </MiddleContentBoxDesc>
            </MiddleContentBox>
            <MiddleContentBox>
              <MiddleContentBoxTitle>
                Electronic, Electrical & Mechanical
              </MiddleContentBoxTitle>
              <MiddleContentBoxDesc>
                <ul style={{ padding: "10px" }}>
                  <li>PLC-Scada, Software making</li>
                  <li>PCB Designing & Development</li>
                  <li>
                    How to Implementation Hardware & Circuit Diagram yourself
                  </li>
                  <li>Learn simulation into Simulator</li>
                </ul>
              </MiddleContentBoxDesc>
            </MiddleContentBox>
          </MiddleContentContainerOut>
        </MiddleContent>
        <BottomContainerBox>
          <BottomContainerTitle>WHAT'S IN IT FOR YOU?</BottomContainerTitle>
          <BottomContainerDesc>
            Electronic circuits are everywhere, from computers and smart phones,
            to home appliances and cars. Think of all the everyday objects that
            are becoming “smart”... in the future, most of the things that we
            own will contain some electronics. Jobs in electronics are in high
            demand and well paid in almost every country!
          </BottomContainerDesc>
          <div
            style={{
              display: "flex",
              margin: "30px 10px",
              justifyContent: "space-around",
              flexFlow: 'row wrap'
            }}
          >
            <BottomImageContainer>
              <img
                src={require("../Assets/Training/5.png")}
                height={200}
                width={250} alt=""
              />
            </BottomImageContainer>
            <BottomImageContainer>
              <img
                src={require("../Assets/Training/Ard.PNG")}
                height={200}
                width={250} alt=""
              />
            </BottomImageContainer>
            <BottomImageContainer>
              <img
                src={require("../Assets/Training/OIP.jfif")}
                height={200}
                width={250} alt=""
              />
            </BottomImageContainer>
            <BottomImageContainer>
              <img
                src={require("../Assets/Training/8.png")}
                height={200}
                width={250} alt=""
              />
            </BottomImageContainer>
          </div>
          <BottomContainerDesc>
            Building electronic products is incredibly rewarding, whether you do
            it professionally or just as a hobby. There is just something
            different and exciting about designing something physical that can
            be hold in one’s hand and that interacts with the outside world, and
            today it has become incredibly easy to get started thanks to cheap
            development boards such as the Arduino and Raspberry Pi, combined
            with the right knowledge.
          </BottomContainerDesc>
        </BottomContainerBox>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Training;
