import React, { useEffect } from 'react'
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { mobile } from '../responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { submitRegistration } from '../redux/APIs/RegistrationAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const Wrapper = styled.div`
  margin: 30px 80px;
`;

const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mobile({ flexDirection: 'column' })}
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  border-radius: 2px;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  padding: 10px;
  &:focus {
    outline: none;
  }
  ${mobile({ padding: "8px", margin: "10px 5px 0px 0px" })}
`;
const Button = styled.button`
  width: 20%;
  border: none;
  padding: 10px;
  margin: 20px 10px 0px 0px;
  background-color: teal;
  cursor: pointer;
  color: white;
  font-weight: 500;
  ${mobile({ width: '70%', padding: '8px 10px' })}

`;
const Message = styled.div`
margin-top: 10px;
color: green;
`;


const Regristration = () => {
  const response = useSelector((state) => state.registration);
  const user = useSelector(state => state.user);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const onSubmit = (values) => {
    let createdAt = moment.utc().format();
    let profile = { ...values, createdAt };
    console.log(profile);
    submitRegistration(dispatch, profile);
  };

  useEffect(() => {
    console.log('registration === ', response);
    if(response.message === 'success') {
      navigate('/');
    }
  }, [response])

  useEffect(() => {
    if(!user.isLoggedIn) {
      Swal.fire({
        titleText: 'Please login to continue Registration.',
        showDenyButton: true,
        showConfirmButton: true,
        icon: 'question',
        confirmButtonText: 'Proceed to Login',
        denyButtonText: 'Continue Exploring',
        denyButtonColor: '#95bccb',
        confirmButtonColor: '#1788b9'
      }).then((result) => {
        if(result.isConfirmed) {
          navigate('/login', { state: { url: location.pathname } });
        }
        else if(result.isDenied || result.isDismissed) {
          navigate('/');
        }
      })
    }
  }, [])
  

  return (
    <Container>
        <Navbar />
        <Title>Student Registration</Title>
        <Wrapper>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Form>
            <Input {...register('name', { required: true })} isValid={errors?.name} placeholder="Full Name" name="name" />
            <Input {...register('age', { required: true })} isValid={errors?.age} placeholder="Age" name="age" />
            <Input {...register('fatherName', { required: true })} isValid={errors?.fatherName} placeholder="Father's Name" name="fatherName" />
            <Input {...register('motherName', { required: true })} isValid={errors?.motherName} placeholder="Mother's Name" name="motherName" />
            <Input {...register('qualification', { required: true })} isValid={errors?.qualification} placeholder="Qualification" name="qualification" />
            <Input {...register('email', { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ })} type='email' isValid={errors?.email} placeholder="Email" name="email" />
            <Input {...register('school', { required: true })} isValid={errors?.school} placeholder="School" name="school" />
            <Input {...register('address', { required: true })} isValid={errors?.address} placeholder="Address" name="address" />
            <Input {...register('mobileNumber', { required: true })} type='number' isValid={errors?.mobileNumber} placeholder="Mobile Number" name="mobileNumber" />
            <Input {...register('intrest', { required: true })} isValid={errors?.intrest} placeholder="Intrests" name="intrest" />
          </Form>
          <Button type="submit">Submit Intrest</Button>
        </form>
        {response.message && <Message>Registration Done Successfully.</Message>}
      </Wrapper>
    </Container>
  )
}

export default Regristration