import { createSlice } from '@reduxjs/toolkit'

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        productList: [],
        isLoading: false
    },
    reducers: {
        getAllProductsStart: (state, action) => {
            state.isLoading = true;
        },
        getAllProductsSuccess: (state, action) => {
            state.isLoading = false;
            state.productList = action.payload;
        },
        getAllProductsFailure: (state, action) => {
            state.isLoading = false;
            state.productList = [];
        },
    }
})

export const { getAllProductsSuccess, getAllProductsFailure, getAllProductsStart } = productsSlice.actions;
export default productsSlice.reducer;