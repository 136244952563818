import React, { useEffect } from "react";
import Announcements from "../components/Announcements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewsLetter from "../components/NewsLetter";
import Products from "../components/Products";
import { userRequest } from "../RequestMethods";
import { useDispatch, useSelector } from "react-redux";
import AliceCarousel from "react-alice-carousel";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useNavigate } from "react-router-dom";
import { resetReducer } from "../redux/registrationRedux";
import {
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { resetPaymentReducer } from "../redux/stripeRedux";

const Image = styled.img`
  width: 100vw;
  height: 500px;
  object-fit: cover;
  ${mobile({ height: "40vh", width: "100vw" })};
  ${tabletPortrait({ height: "50vh", width: "100vw" })}
  ${tabletLandscape({ height: "70vh", width: "100vw" })}
`;

const OuterBox = styled.div`
  height: auto;
  background-color: #ffe3d9;
`;

const OuterBoxAchievement = styled.div`
  height: auto;
  padding: 0px 30px 30px 30px;
  background-color: #92e7c563;
`;

const HeaderDiv = styled.div`
  padding-top: 30px;
  text-align: center;
  height: auto;
`;

const HeaderText = styled.p`
  padding: 40px;
  font-size: 20px;
  margin: 10px 0px 0px 0px;
  ${mobile({ fontSize: "14px" })}
`;

const FooterText = styled.p`
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  margin: 10px 0px 0px 0px;
  ${mobile({ fontSize: "20px" })}
`;

const ExpertiseSection = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mobile({
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  })}
`;

const AchievementSection = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  ${mobile({
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  })}
`;

const CardA = styled.div`
  background-color: #fff;
  width: 450px;
  margin-bottom: 20px;
  ${mobile({ width: "300px", marginBottom: '20px' })}
`;

const Card = styled.div`
  background-color: #fff;
  width: 300px;
  ${mobile({ width: "300px", marginBottom: '20px' })}
`;

const Home = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let username =
    typeof localStorage.getItem("persist:root") === "undefined"
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.username
      : "";

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1, itemsFit: "contain" },
  };

  const items = [
    <div>
      <Image src={require("../Assets/Slider/img1.jpg")} alt="" />
    </div>,
    <div>
      <Image src={require("../Assets/Slider/img2.jpg")} alt="" />
    </div>,
    <div>
      <Image src={require("../Assets/Slider/img3.jpg")} alt="" />
    </div>,
    <div>
      <Image src={require("../Assets/Slider/img4.jpg")} alt="" />
    </div>,
  ];

  useEffect(() => {
    if (isLoggedIn) {
      userRequest
        .get("/user/userInfo", { username: username })
        .then((response) => {})
        .catch((err) => {
          navigate("/sessionTimeout");
        });
    }
    dispatch(resetReducer());
    dispatch(resetPaymentReducer());
    // window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Announcements />
      <Navbar />
      <AliceCarousel
        items={items}
        responsive={responsive}
        // autoHeight
        autoPlay
        // autoPlayControls
        autoPlayStrategy="none"
        autoPlayInterval={5000}
        animationDuration={2000}
        animationType="fadeout"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
      />
      <OuterBox>
        <HeaderDiv>
          <h2>Our Specialisations</h2>
          <HeaderText>
            We have successfully executed numerous projects, ranging from
            small-scale assignments to large-scale initiatives with our
            expertise in software, hardware, automation and electronics.
          </HeaderText>
        </HeaderDiv>
        <ExpertiseSection>
          <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 170 }}
              image={require("../Assets/Homepage/software.jpg")}
              title="Software Development"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Software Development
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We have a proven track record of developing robust and
                user-friendly web applications. From conceptualization to
                deployment, our talented developers and designers work closely
                with clients to create tailor-made web apps that deliver
                exceptional user experiences and achieve business objectives.
              </Typography>
            </CardContent>
          </Card>
          <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 170 }}
              image={require("../Assets/Homepage/electronics.jpg")}
              title="Electronics"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Electronics and Electricals
              </Typography>
              <Typography variant="body2" color="text.secondary">
                With a deep understanding of electronic products, we are
                committed to pushing the boundaries of innovation. Our team
                combines technical expertise with creative thinking to develop
                unique and impactful electronic products that meet the evolving
                needs of our customers.
              </Typography>
            </CardContent>
          </Card>
          <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 170 }}
              image={require("../Assets/Homepage/training.jfif")}
              title="Software Development"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Training
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our company excels in providing comprehensive and
                industry-relevant training programs to college students,
                empowering them with the skills and knowledge needed to succeed
                in today's competitive job market.
              </Typography>
            </CardContent>
          </Card>
          <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 170 }}
              image={require("../Assets/Homepage/automation.jfif")}
              title="Automation Solution"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Automation Solution
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We specialize in delivering cutting-edge automation solutions
                that streamline processes, increase efficiency, and drive
                business growth. Our team of experts leverages the latest
                technologies to design and implement tailored automation
                solutions for our clients.
              </Typography>
            </CardContent>
          </Card>
        </ExpertiseSection>
      </OuterBox>
      <OuterBoxAchievement>
        <HeaderDiv>
          <h2>Our Success Stories</h2>
          <HeaderText>
            We have achieved significant milestones in providing top-notch
            training programs, automation solutions, and electronic product
            development services to a diverse range of clients.
          </HeaderText>
        </HeaderDiv>
        <AchievementSection>
          <CardA className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 190 }}
              image={require("../Assets/Homepage/Afcons.png")}
              title="Software Development"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Afcons Infrastructure Web Application
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We have delivered a robust and feature packed employee
                management system with QR code ID Cards with Attendance System
                web application to Afcons Infrastructure Limited.
              </Typography>
            </CardContent>
          </CardA>
          <CardA className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 190 }}
              image={require("../Assets/Homepage/Supertech.jpg")}
              title="DG Automation"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Supertech Limited DG Automation
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We have automated the Electricity / DG System for Supertech Pvt.
                Ltd.
              </Typography>
            </CardContent>
          </CardA>
          <CardA className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 200 }}
              image={require("../Assets/Homepage/traffic.jfif")}
              title="Software Development"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Traffic Signages and Flex Boards
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We have delivered Traffic Signage Boards and Flex Boards at
                various sites.
              </Typography>
            </CardContent>
          </CardA>
          <CardA className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1l3yh5l-MuiPaper-root-MuiCard-root">
            <CardMedia
              sx={{ height: 170 }}
              image={require("../Assets/Homepage/goodyear.jfif")}
              title="Automation Solution"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Goodyear AutoExpo Project
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We have designed the mechanism for providing the feeling and
                sensation of driving a real car while sitting in front of
                screens in AutoExpo for GoodYear.
              </Typography>
            </CardContent>
          </CardA>
        </AchievementSection>
        <FooterText>And many more...</FooterText>
      </OuterBoxAchievement>
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
