import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Announcements from "../components/Announcements";
import styled from "styled-components";
import { mobile } from "../responsive";
import Footer from "../components/Footer";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
  ${mobile({ fontSize: "24px" })}
`;

const Wrapper = styled.div`
  margin: 20px;
  padding: 0px 20px;
`;

const Desc = styled.p`
  ${mobile({ fontSize: "14px" })}
`;

const OrderedListItem = styled.li`
  margin-top: 10px;
  ${mobile({ fontSize: "14px" })}
`;

const BoldText = styled.p`
  font-weight: 600;
  text-decoration: underline;
  ${mobile({ fontSize: "14px" })}
`;

const ContactInfo = styled.p`
  font-weight: 500;
  margin: 0px;
  ${mobile({ fontSize: "14px" })}
`;

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>Return and Refund Policy</Title>
      <Wrapper>
        <BoldText>Effective Date: 10-05-2023</BoldText>
        <Desc>
          This Refund Policy ("Policy") outlines the terms and conditions
          regarding refunds for products purchased through IMPACT TECHNOLOGIES.
          By making a purchase on our website, you agree to comply with this
          Policy. Please read this Policy carefully before making a purchase.
        </Desc>
        <ol>
          <OrderedListItem>
            <BoldText>Eligibility for Refunds</BoldText>
            <ul>
              <li>
                <b>Defective or Damaged Products:</b> If you receive a product
                that is defective, damaged, or significantly different from its
                description on our website, you may be eligible for a refund.
                Please notify us within 15 days of
                receiving the product, providing details of the issue, and any
                supporting documentation or evidence.
              </li>
              <li>
                <b>Non-Delivery:</b> If you have not received your order within
                the estimated delivery timeframe or if the tracking information
                shows that the product has not been delivered, please contact
                us. We will investigate the issue and, if necessary, provide a
                refund or arrange for a replacement shipment.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Return Process</BoldText>
            <ul>
              <li>
                <b>Contact Customer Support:</b> To initiate a refund request,
                please contact our customer support team by any method mentioned on our website. Provide your order details, including the order
                number, product information, and the reason for the refund
                request.
              </li>
              <li>
                <b>Return Authorization:</b> In certain cases, we may require
                you to return the product to us before processing the refund. If
                this is necessary, our customer support team will provide you
                with a Return Authorization (RA) number and instructions on how
                to return the item.
              </li>
              <li>
                <b>Packaging and Condition:</b> If a return is required, please
                ensure that the product is returned in its original packaging,
                unused, and in the same condition as when you received it.
                Failure to comply with this requirement may result in a partial
                refund or rejection of the refund request.{" "}
              </li>
              <li>
                <b>Return Shipping:</b> If you are instructed to return the
                product, you will be responsible for the cost of shipping the
                item back to us, unless the return is due to a defective or
                damaged product or an error on our part. We recommend using a
                trackable shipping method and retaining proof of shipment.{" "}
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Return Shipping:</BoldText>
            <Desc>
              We may share your personal information with third parties in the
              following circumstances:
            </Desc>
            <ul>
              <li>
                <b>Refund Evaluation:</b> Once we receive the returned product
                or assess the refund request, we will evaluate its eligibility
                for a refund. This may involve inspecting the product and
                verifying the details provided.
              </li>
              <li>
                <b>Refund Options:</b> If your refund request is approved, we
                will issue the refund using the original payment method used for
                the purchase. Refunds may be provided as:
                <ol type="a">
                  <li>
                    Store Credit: You may choose to receive a store credit that
                    can be used for future purchases on our website.
                  </li>
                  <li>
                    Original Payment Method: If feasible, the refund will be
                    credited back to the original payment method used during the
                    purchase.
                  </li>
                </ol>
              </li>
              <li>
                <b>Refund Timeframe:</b> The time it takes for the refunded
                amount to be credited to your account may vary depending on the
                payment method and financial institution involved. Please allow
                for a reasonable processing period.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Non-Refundable Items</BoldText>
            <Desc>
              Certain items are not eligible for refunds unless they are
              defective or damaged upon receipt. These may include:
              <ul>
                <li>Digital products or downloadable content.</li>
                <li>Customized or personalized items.</li>
                <li>Perishable goods, such as food or flowers.</li>
                <li>
                  Intimate or sanitary products, such as underwear or beauty
                  products (unless unopened and in original packaging).
                </li>
              </ul>
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Modifications to the Refund Policy</BoldText>
            <Desc>
              We reserve the right to update or modify this Refund Policy at any
              time without prior notice. Any changes will be effective
              immediately upon posting the revised policy on our website. It is
              your responsibility to review this Policy periodically to stay
              informed about our refund practices.
            </Desc>
            <Desc>
              If you have any questions or concerns regarding this Refund
              Policy, please contact our customer support team using the
              information provided on our website.
            </Desc>
            <Desc>
              By making a purchase on our website, you acknowledge that you have
              read, understood, and agree to be bound by this Refund Policy.
            </Desc>
          </OrderedListItem>
        </ol>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default RefundPolicy;
