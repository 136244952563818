import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Announcements from "../components/Announcements";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import { useDispatch } from "react-redux";
import { getAllProd } from "../redux/APIs/ProductAPIs";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Products from "../components/Products";
import NewsLetter from "../components/NewsLetter";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

const Container = styled.div``;
const Title = styled.h1`
  margin: 20px;
  ${mobile({ fontSize: "24px" })}
`;

const SearchContainer = styled.div`
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 2px;
  ${mobile({ marginTop: "8px" })}
`;

const Input = styled.input`
  border: none;
  &:focus {
    outline: none;
  }
  ${mobile({ width: "105px" })}
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
  ${mobile({ marginRight: "0px" })}
`;
const Icon = styled.div`
  display: flex;
  color: grey;
  &: hover {
    cursor: pointer;
    color: #108ff0;
  }
`;
const Projects = () => {
  const location = useLocation();
  const cat = location.pathname.split('/')[2];
  const [clearFilter, setClearFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setfilters] = useState({ category: ["Project"] });
  const [sort, setSort] = useState('newest'); 
  const dispatch = useDispatch();

  const onSubmit = () => {
    getAllProd(dispatch, filters, "", "", searchText);
    setClearFilter(true);
  };
  const handleOnChnage = (e) => {
    setfilters({ ...filters, [e.target.name]: [e.target.value] });
  };
  const clearAllFilters = () => {
    setfilters({ category: ["Project"] });
    getAllProd(dispatch, filters, "", "", "");
    setClearFilter(false);
    setSearchText("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>Projects and Components</Title>
      <FilterContainer>
        <Filter>
          <FilterText>Search Projects:</FilterText>
          <SearchContainer>
            <Input
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) =>
                e.code === "Enter" &&
                searchText.trim().length >= 3 &&
                onSubmit()
              }
              value={searchText}
            />
            <Icon
              onClick={() => {
                searchText.trim().length >= 3 && onSubmit();
              }}
            >
              {searchText.trim().length >= 3 && (
                <SearchIcon style={{ fontSize: 16 }} />
              )}
            </Icon>
          </SearchContainer>
          {clearFilter && (
            <Icon
              onClick={() => {
                clearAllFilters();
              }}
            >
              <FilterAltOffIcon style={{ fontSize: 22, marginLeft: "10px" }} />
            </Icon>
          )}
        </Filter>
      </FilterContainer>
      <Products cat={cat} filters={filters} sort={sort} />
      <NewsLetter />
      <Footer />
    </Container>
  );
};

export default Projects;
