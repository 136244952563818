import { publicRequest } from "../../RequestMethods";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  singupStart,
  signupFailure,
  signupSuccess,
  changePasswordSuccess,
  changePasswordStart,
  changePasswordFailure,
  signupVerifySuccess,
  signupVerifyFailure,
  signUpVerifyStart,
} from "../userRedux";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  await publicRequest
    .post("/auth/login", user)
    .then((resp) => {
      dispatch(loginSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(loginFailure(err.response.data.message));
    });
};

export const signup = async (dispatch, user) => {
  dispatch(singupStart());
  await publicRequest
    .post("/auth/signup", user)
    .then((resp) => {
      if(resp.status === 200) {
        dispatch(signupSuccess(resp.data));
      }
      else {
        dispatch(signupFailure('Internal Server Error'));
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
      dispatch(signupFailure(err.response.data));
    });
};

export const signupVerify = async (dispatch, data) => {
  dispatch(signUpVerifyStart());
  await publicRequest
    .post("/auth/verifyRegistration", data)
    .then((resp) => {
      if(resp.status === 201) {
        dispatch(signupVerifySuccess(resp.data));
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
      dispatch(signupVerifyFailure(err.response.data));
    });
};



export const sendOtp = async (dispatch, user) => {
  dispatch(changePasswordStart());
  await publicRequest
    .post("/auth/forgotPassword", user)
    .then((resp) => {
      dispatch(changePasswordSuccess(resp.data.message));
    })
    .catch((err) => {
      console.log(err.response.data.message);
      dispatch(changePasswordFailure(err.response.data.message));
    });
};

export const changePassword = async (dispatch, user) => {
  dispatch(changePasswordStart());
  await publicRequest
    .post("/auth/resetPassword", user)
    .then((resp) => {
      dispatch(changePasswordSuccess(resp.data.message));
    })
    .catch((err) => {
      console.log(err.response.data.message);
      dispatch(changePasswordFailure(err.response.data.message));
    });
};
