import { createSlice } from "@reduxjs/toolkit";

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
    isProcessing: false,
    error: false,
    message: "",
  },
  reducers: {
    paymentStart: (state, action) => {
      state.isProcessing = true;
      state.error = false;
      state.message = "";
    },
    paymentFailed: (state, action) => {
      state.isProcessing = false;
      state.error = true;
      if (action.payload === 401) state.message = "Unauthorized";
      else state.message = "";
    },
    paymentSuccess: (state, action) => {
      state.isProcessing = false;
      state.error = false;
      state.message = "Success";
    },
    resetPaymentReducer: (state, action) => {
      state.isProcessing = false;
      state.error = false;
      state.message = "";
    }
  },
});

export const { paymentFailed, paymentStart, paymentSuccess, resetPaymentReducer } =
  stripeSlice.actions;
export default stripeSlice.reducer;
