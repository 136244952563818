import moment from "moment";
import { userRequest } from "../../RequestMethods";
import { paymentFailed, paymentStart, paymentSuccess } from "../stripeRedux";
import { orderCreationFailed, orderCreationInitiated, orderCreationSuccess } from "../orderRedux";

const logo = require("../../Assets/Logo/ImpactFav.png");

export const makePayment = async (dispatch, data) => {
  dispatch(paymentStart());
  await userRequest
    .post("/stripe/create-checkout-session", {
      payload: data.payload,
      userId: data.userId,
    })
    .then((res) => {
      dispatch(paymentSuccess());
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(paymentFailed(error.response.status));
    });
};

export const makePaymentByRazorPay = async (
  dispatch,
  data,
  order_info,
  addressId
) => {
  dispatch(paymentStart());
  await userRequest
    .post("/payment/orders", data)
    .then((res) => {
      let options = {
        key: "rzp_live_LVvOP5apINRzJ9", // Enter the Key ID generated from the Dashboard
        amount: res.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: res.data.currency,
        name: "IMPACT TECHNOLOGIES",
        image: logo,
        description: "Test Transaction",
        order_id: res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          console.log("handler resp--", response);
          userRequest
            .post("/payment/verify", response)
            .then((resp) => {
              console.log("verify Signature", resp.data);
              if (resp.data.validSignature === true) {
                dispatch(paymentSuccess());
              }
              dispatch(orderCreationInitiated());
              let ordersArr = [];
              order_info.forEach((order) => {
                let ordersObj = {
                  order_id: response.razorpay_order_id,
                  payment_id: response.razorpay_payment_id,
                  amount: order.price,
                  created_at: moment().utc().format(),
                  expected_del: moment().add(5, "days").utc().format(),
                  product_id: order.id,
                  quantity: order.quantity,
                  del_add_id: addressId,
                  status: "Order Placed",
                };
                ordersArr.push(ordersObj);
              });
              userRequest.post("/orders/saveOrder", {order_id: response.razorpay_order_id,
                transaction_id: response.razorpay_payment_id, orderInfo: ordersArr}).then(resp => {
                  dispatch(orderCreationSuccess());
                });
            })
            .catch((err) => {
              console.log(err);
            });
        },
        prefill: {
          name: data.user.currentUser.user.first_name + ' ' + data.user.currentUser.user.last_name,
          email: data.user.currentUser.user.email,
          contact: data.user.currentUser.user.mobile,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      let rzp = new window.Razorpay(options);
      rzp.open();
      rzp.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
        dispatch(paymentFailed());
      });
      // if (res.data.url) {
      //   window.location.href = res.data.url;
      // }
    })
    .catch((error) => {
      console.log(error);
      dispatch(orderCreationFailed())
      dispatch(paymentFailed(error.response.status));
    });
};

export const makePaymentByPhonePe = async (
  dispatch,
  data,
  order_info,
  addressId
) => {
  dispatch(paymentStart());
  userRequest
    .post("/payment/createTxn", {
      amount: data.amount * 100,
      delAdd: addressId,
    })
    .then((resp) => {
      let txnID = resp.data[0].transaction_id;
      const payload = {
        merchantId: "M1WG7D3REASY",
        merchantTransactionId: txnID,
        merchantUserId: data.user.currentUser.user.username,
        amount: data.amount * 100,
        redirectUrl: "http://localhost:3000/orderStatus",
        redirectMode: "REDIRECT",
        callbackUrl: "http://localhost:4000/api/payment/status",
        mobileNumber: "9999999999",
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      };

      userRequest
        .post("/payment/initiatePayment", payload)
        .then((resp) => {
          window.location.href =
            resp.data.data.instrumentResponse.redirectInfo.url;
        })
        .catch((err) => {
          console.log(err);
        });
    });
};

export const verifyPaymentByPhonePe = async (dispatch, order_info) => {
  userRequest.get("/payment/getLastTxnStatus").then((resp) => {
    userRequest
      .get(`/payment/verifyPayment/${resp.data.transaction_id}`)
      .then((response) => {
        if(response.data.code === "PAYMENT_SUCCESS") {
          let ordersArr = [];
          order_info.forEach((order) => {
            let ordersObj = {
              amount: order.price,
              created_at: moment().utc().format(),
              expected_del: moment().add(5, "days").utc().format(),
              product_id: order.id,
              quantity: order.quantity,
              del_add_id: resp.data.delivery_add,
              status: "Order Placed",
            };
            ordersArr.push(ordersObj);
          });
          userRequest.post("/orders/saveOrder", {order_id: resp.data.order_id,
            transaction_id: resp.data.transaction_id, orderInfo: ordersArr});
          dispatch(paymentSuccess());
        }
        dispatch(paymentFailed());
      })
      .catch((err) => {
        console.log(err);
        dispatch(paymentFailed());
      });
  });
};
