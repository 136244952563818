import { publicRequest, userRequest } from "../../RequestMethods";
import { registrationFailure, registrationStart, registrationSuccess, resetReducer } from "../registrationRedux";

export const submitRegistration = async (dispatch, user) => {
    dispatch(registrationStart());
    await userRequest
      .post("/user/registrationProfile", user)
      .then((resp) => {
        console.log('.then block')
        dispatch(registrationSuccess(resp.data));
        // dispatch(resetReducer());
      })
      .catch((err) => {
        dispatch(registrationFailure(err.response.data.message));
      });
  };
  