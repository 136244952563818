import { publicRequest, userRequest } from "../../RequestMethods";
import {
  getProductStart,
  getProductSuccess,
  getProductFailure,
  addProductSuccess,
  addProductFailure,
  addProductStart,
} from "../productRedux";
import { getAllProductsFailure, getAllProductsStart, getAllProductsSuccess } from "../productsRedux";

export const getAllProd = async (dispatch, filters, sort, cat, text) => {
  // console.log(filters, Object.keys(filters).length);
  let query = {};
  // console.log(typeof sort !== "undefined" && typeof filters !== "undefined" && sort.lenght !== 0)
  // if ( typeof sort !== "undefined" && typeof filters !== "undefined" && sort.lenght !== 0 && typeof text !== 'undefined' && text.trim().lenght !== 0) {
  if ( typeof sort !== "undefined" && typeof filters !== "undefined" && sort.lenght !== 0) {
    query = { filter: {} };
    filters.category && filters.category[0] !== "" ? (query.filter.category = filters.category) : delete query.filter.category;
    filters.color && filters.color[0] !== "" ? (query.filter.color = filters.color) : delete query.filter.color;
    cat && cat !== "" ? (query.category = cat) : delete query.category;
    text && text.trim() !== "" ? (query.text = text) : delete query.text;
    if (text && Object.keys(query.filter).length === 0) {
      query = {text: text};
    }
    else if (Object.keys(query.filter).length === 0) {
      query = {};
    }
  }
  const config = {
    headers: {
      query: JSON.stringify(query),
    },
  };
  dispatch(getAllProductsStart());
  publicRequest.get("/products", config).then((resp) => {
    dispatch(getAllProductsSuccess(resp.data));
  })
  .catch((err) => {
    dispatch(getAllProductsFailure());
    console.log(err);
  });
};

export const getSingleProduct = async (dispatch, id) => {
  dispatch(getProductStart());
  await publicRequest.get("/product/" + id).then((resp) => {
      dispatch(getProductSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(getProductFailure());
      console.error(err);
    });
};

export const addProduct = async (dispatch, product) => {
  dispatch(addProductStart());
  await userRequest.post("/createProducts", product).then((resp) => {
      dispatch(addProductSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(addProductFailure());
      console.error(err);
    });
};