import { createSlice } from '@reduxjs/toolkit'

const productSlice = createSlice({
    name: 'product',
    initialState: {
        productDetails: {},
        isFetching: false
    },
    reducers: {
        getProductStart: (state, action) => {
            state.isFetching = true;
        },
        getProductSuccess: (state, action) => {
            state.productDetails = action.payload;
            state.isFetching = false;
        },
        getProductFailure: (state, action) => {
            state.isFetching = false;
        },
        addProductStart: (state, action) => {
            state.isFetching = true;
        },
        addProductSuccess: (state, action) => {
            state.productDetails = action.payload;
            state.isFetching = false;
        },
        addProductFailure: (state, action) => {
            state.isFetching = false;
        },
        emptyProductReducer: (state) => {
            state.productDetails = {}
            state.isFetching = false;
        }
    }
})

export const { getProductStart, getProductSuccess, getProductFailure, addProductFailure, addProductStart, addProductSuccess, emptyProductReducer } = productSlice.actions;
export default productSlice.reducer;