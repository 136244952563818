import React from "react";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import Thankyou from "./pages/Thankyou";
import loader from './Assets/Loader2.gif';
import styled from "styled-components";
import { mobile } from "./responsive";
import AboutUs from "./pages/AboutUs";
import Training from "./pages/Training";
import AutoSoln from "./pages/AutoSoln";
import Regristration from "./pages/Regristration";
import SessionTimeout from "./pages/SessionTimeout";
import VerifyRegistration from "./pages/VerifyRegistration";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import RefundPolicy from "./pages/RefundPolicy";
import CheckoutPage from "./pages/CheckoutPage";
import MyOrders from "./pages/MyOrders";
import Projects from "./pages/Projects";

const LazyCart = React.lazy(() => import("./pages/Cart"));
const LazyWishlist = React.lazy(() => import("./pages/Wishlist"));
const AddProduct = React.lazy(() => import("./pages/AddProduct"));
const Product = React.lazy(() => import("./pages/Product"));

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  ${mobile({ height: "50vh" })}
`;

const Loader = styled.img`
  object-fit: cover;
  width: 130px;
  ${mobile({ height: "50vh" })}
`;

function App() {
  return (
    <React.Suspense fallback={<Wrapper><Loader src={loader} /></Wrapper>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<LazyCart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/myOrders" element={<MyOrders />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/training" element={<Training />} />
          <Route path="/automation" element={<AutoSoln />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/products/:category" element={<ProductList />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/addProduct" element={<AddProduct />} />
          <Route path="/wishlist" element={<LazyWishlist />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/orderStatus" element={<Thankyou />} />
          <Route path="/registerNow" element={<Regristration />} />
          <Route path="/sessionTimeout" element={<SessionTimeout />} />
          <Route path="/verify" element={<VerifyRegistration />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
          <Route path="/checkout" element={<CheckoutPage />} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
